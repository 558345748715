import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import ContentfulImage from "../ContentfulImage";

const LogisticsStage = ({
  title,
  text,
  image,
}: {
  title: string;
  text: string;
  image: string;
}) => {
  return (
    <Wrapper container justify="flex-end">
      <TextWrapper item xs={12} sm={5}>
        <Headline variant="h1">{title}</Headline>
        <Text variant="body1">{text}</Text>
      </TextWrapper>
      <ImageWrapper item xs={12} sm={7}>
        <ContentfulImage url={image} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default LogisticsStage;

const Wrapper = styled(Grid)(
  ({ theme }) => `
  max-width: 1280px;
  margin: 0 auto ${theme.spacing(3)}px;
    padding: ${theme.spacing(8, 3, 0)};
    position: relative;
    ${theme.breakpoints.up("sm")} {
        padding: ${theme.spacing(15, 0, 0)};
    }
`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    order: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${theme.breakpoints.up("sm")} {
        order: 0;
        bottom: 0px;
        z-index: 1;
    }
`
);

const Text = styled(Typography)(
  ({ theme }) => `
    ${theme.breakpoints.up("sm")} {
        width: 150%;
    }
    @media (min-width: 767px){
        width: 100%
    }
    ${theme.breakpoints.up("md")} {
        max-width: 476px;
    }
`
);

const ImageWrapper = styled(Grid)(
  ({ theme }) => `
    order: 1;
    margin-bottom: ${theme.spacing(4)}px;
    margin-top: ${theme.spacing(4)}px;

    ${theme.breakpoints.up("sm")} {
        order: 1;
        margin-bottom:200px;
        margin-top: 0

    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
    max-width: 376px;
    margin-bottom: ${theme.spacing(3)}px;
    ${theme.breakpoints.up("sm")} {
        margin-bottom: ${theme.spacing(2)}px;
    }
    @media(min-width: 767px) {
        margin-bottom: ${theme.spacing(3)}px;
    }
 `
);
