import React, { useState, useEffect } from "react";
import { CheckCircle, Error } from "@material-ui/icons";
import styled from "styled-components";

interface InputProps {
  label: string;
  type: string;
  name: string;
  showValidState: boolean;
  required: boolean;
  placeholder?: string;
  errorMessage?: string;
}

function Input({
  label,
  type,
  name,
  showValidState,
  required,
  placeholder,
  errorMessage,
}: InputProps) {
  const [focusedOnce, setFocusedOnce] = useState(false);
  useEffect(() => {
    setFocusedOnce(false);
  }, [showValidState]);
  useEffect(() => {
    if (!document) {
      return;
    }

    document.addEventListener(
      "invalid",
      (e) => {
        e.preventDefault();
        setFocusedOnce(true);
      },
      true
    );
  }, []);

  return (
    <ContactFieldContainer>
      <ContactLabel>{label}</ContactLabel>
      {type === "textarea" ? (
        <ContactTextArea
          required={false}
          onFocus={() => setFocusedOnce(true)}
          placeholder={placeholder}
          name={name}
        />
      ) : (
        <ContactField
          type={type === "email" ? "text" : type}
          name={name}
          required={required}
          className={`${focusedOnce ? "focusedOnce" : ""}`}
          placeholder={placeholder}
          onBlur={() => setFocusedOnce(true)}
          pattern={
            type === "email"
              ? "^[^(\\.)][a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,3}"
              : undefined
          }
        />
      )}
      <ErrorMessage className={"validation-message"}>
        {errorMessage}
      </ErrorMessage>
      <div className="invalid">
        <Error />
      </div>
      <div className="valid">
        <CheckCircle />
      </div>
    </ContactFieldContainer>
  );
}

export default Input;
const ContactFieldContainer = styled.div`
  max-width: 500px;
  width: 100%;
  margin-bottom: 43px;
  position: relative;
  & .invalid {
    display: none;
  }
  & .valid {
    display: none;
  }
  & .validation-message {
    display: none;
  }
`;

const ContactLabel = styled.label`
  opacity: 0.7;
  line-height: 18px;
  font-size: 14px;
  color: #212529;
  margin-bottom: 5px;
  display: block;
`;
const ContactField = styled.input`
  display: inline-block;
  height: 45px;
  border: none;
  width: 100%;
  border-radius: 0;
  padding: 15px;
  color: #212529;
  font-size: 15px;

  &::placeholder {
    font-size: 15px;
  }
  &:focus {
    box-shadow: none;
    outline: solid 1px #5dc1e6;
  }

  &:hover {
    outline: solid 1px #898988;
  }
  &.focusedOnce:required {
    &:valid[pattern] {
      outline: #59c39d solid 1px;
      & ~ .valid {
        display: block;
        position: absolute;
        top: 33px;
        right: 15px;
        color: #59c39d;
        font-size: 20px;
      }
    }
    &:invalid {
      outline: #e82449 solid 1px;
      & ~ .validation-message {
        display: block;
      }
      & ~ .invalid {
        display: block;
        position: absolute;
        top: 33px;
        right: 15px;
        color: #e82449;
      }
    }
  }
`;
const ErrorMessage = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
  color: #e82449;
`;
const ContactTextArea = styled.textarea`
  display: inline-block;
  max-width: 500px;
  width: 100%;
  height: 150px;
  color: #212529;
  font-size: 15px;
  resize: none;
  border: none;
  padding: 15px;
  border-radius: 0;

  &:focus,
  &:active {
    outline: 1px solid #5dc1e6;
    box-shadow: none;
  }

  &::placeholder {
    font-size: 15px;
  }

  &:hover {
    outline: solid 1px #898988;
  }
`;
