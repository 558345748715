import React, { FormEvent, Fragment, useState } from "react";
import styled from "styled-components";
import { Container, Grid, IconButton, Snackbar } from "@material-ui/core";
import { Cancel, CheckCircle, Mail, Phone } from "@material-ui/icons";
import { FormattedMessage, useIntl } from "gatsby-plugin-intl";
import Input from "../components/input";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";

function Contact({ contact }) {
  const [showValidState, setShowValidState] = useState(false);

  const [severity, setSeverity] = useState<undefined | "success" | "error">(
    undefined
  );
  const [snackMessage, setSnackMessage] = useState("formSentSuccessfully");
  const intl = useIntl();
  const formFields = [
    {
      labelKey: "firstName",
      type: "text",
      name: "firstName",
      required: true,
      errorMessage: intl.formatMessage({ id: "firstNameValidationMessage" }),
    },
    {
      labelKey: "lastName",
      type: "text",
      name: "secondName",
      required: true,
      errorMessage: intl.formatMessage({ id: "lastNameValidationMessage" }),
    },
    {
      labelKey: "company",
      type: "text",
      name: "company",
      required: true,
      errorMessage: intl.formatMessage({ id: "companyValidationMessage" }),
    },
    {
      labelKey: "email",
      type: "email",
      name: "email",
      required: true,
      errorMessage: intl.formatMessage({ id: "emailValidationMessage" }),
    },
    {
      labelKey: "phoneNumber",
      type: "phone",
      name: "phone",
      required: false,
      placeholder: "telephoneNumberPlaceholder",
    },
    {
      labelKey: "message",
      type: "textarea",
      name: "message",
      required: false,
      placeholder: "messagePlaceholder",
    },
  ];

  async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    try {
      let response = await axios.post(
        "https://www.depot-online.de/api/contact/logistics",
        data
      );
      if (response.status === 200) {
        setSeverity("success");
        setSnackMessage("formSentSuccessfully");
        (document.getElementById("contact") as HTMLFormElement)?.reset();
        setShowValidState(!showValidState);
      }
    } catch (e) {
      setSeverity("error");
      setSnackMessage("formSentFailure");
    }
  }
  return (
    <Fragment>
      <Snackbar
        open={!!severity}
        autoHideDuration={6000}
        onClose={() => setSeverity(undefined)}
      >
        <Alert severity={severity}>
          {severity === "success" ? <CheckCircle /> : <Cancel />}
          <p>
            <FormattedMessage id={snackMessage} />
          </p>
          <IconButton
            style={{ marginLeft: "auto" }}
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSeverity(undefined)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Alert>
      </Snackbar>
      <ContentContainer className="contact">
        <Container>
          <Grid container>
            <Grid item md={6}>
              <ContactInfoContainer>
                <ContactInfoTitle>{contact.title}</ContactInfoTitle>
                <ContactInfoText>
                  <FormattedMessage id={"contactSubtitle"}></FormattedMessage>
                </ContactInfoText>
                <ContactInfoListItem>
                  <IconWrapper>
                    <Phone style={{ fontSize: "18px", marginBottom: "6px" }} />
                  </IconWrapper>
                  <span>{contact.telephoneLabel}</span>
                </ContactInfoListItem>

                <ContactInfoListItem>
                  <IconWrapper>
                    <Mail style={{ fontSize: "18px" }} />
                  </IconWrapper>
                  <MailToLink href={`mailto:${contact.mailAdress}`}>
                    {contact.mailAdress}
                  </MailToLink>
                </ContactInfoListItem>
              </ContactInfoContainer>
            </Grid>
            <Grid item md={6}>
              <ContactFormContainer
                onSubmit={(event) => handleOnSubmit(event)}
                id={"contact"}
              >
                {formFields.map((field) => (
                  <Input
                    key={field.name}
                    label={intl.formatMessage({ id: field.labelKey })}
                    type={field.type}
                    name={field.name}
                    required={field.required}
                    placeholder={
                      field.placeholder
                        ? intl.formatMessage({ id: field.placeholder })
                        : ""
                    }
                    showValidState={showValidState}
                    errorMessage={
                      field.errorMessage
                        ? intl.formatMessage({ id: field.errorMessage })
                        : ""
                    }
                  />
                ))}

                <ContactText>
                  <FormattedMessage id={"contactFormTerms"}></FormattedMessage>
                </ContactText>
                <SubmitButton
                  type={"submit"}
                  value={intl.formatMessage({ id: "submit" })}
                />
              </ContactFormContainer>
            </Grid>
          </Grid>
        </Container>
      </ContentContainer>
    </Fragment>
  );
}

Contact.propTypes = {};

export default Contact;
interface AlertProps {
  severity?: "success" | "error";
}
const Alert = styled.div<AlertProps>(
  ({ severity }) => `
  width: 318px;
  height: 50px;
  border-radius: 2px;
  background-color: ${
    severity === "success" ? "#3a9376" : severity === "error" ? "#e82449" : ""
  };
  color: white;
  padding-right: 16px;
  padding-left: 16px;
  display:flex;
  align-items: center;
  p {
      margin: 0;
      margin-left: 8px;
      font-size: 12px;
      font-weight: 600;
   }
`
);
const ContentContainer = styled.div`
  background-color: #eee9e3;
  color: #212529;
  padding-top: 60px;
  padding-bottom: 85px;
  font-family: "Montserrat";
`;
const ContactInfoContainer = styled.div(
  ({ theme }) => `
  padding-left: 0;
  ${theme.breakpoints.up("md")}{
  padding-left: 72px;  }
`
);

const ContactFormContainer = styled.form(
  ({ theme }) => `
  padding-right: 0;
  line-height: 1;
  padding-bottom: 20px

    ${theme.breakpoints.up("md")}{
  padding-bottom: 0
  padding-right: 85px;
  }
`
);

const ContactText = styled.p`
  font-size: 14px;
  margin-bottom: 40px;
  max-width: 500px;
  width: 100%;
  line-height: 17px;
  color: #212529;
`;
const IconWrapper = styled.span`
  margin-right: 8px;
`;
const SubmitButton = styled.input`
  appearance: none;
  border: none;
  padding: 22px;
  cursor: pointer;
  background-color: #222;
  color: #eee9e3;
  max-width: 500px;
  width: 100%;
  font-size: 14px;
`;

const ContactInfoTitle = styled.div`
  font-size: 35px;
  line-height: 43px;
  font-weight: bold;
  margin-bottom: 8px;
`;

const ContactInfoText = styled.p`
  font-size: 15px;
  margin-bottom: 37px;
  line-height: 19px;
`;

const ContactInfoListItem = styled.div`
  font-size: 15px;
  margin-bottom: 8px;
  color: #212529;
`;
const MailToLink = styled.a`
  color: #212529;
  :&hover: {
    color: #212529;
  }
`;
