import React from "react";

import Layout from "src/common/Layout";
import SEO from "src/common/components/seo";
import { graphql } from "gatsby";
import { LogisticsPageQuery } from "types/graphql-types";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import { Grid } from "@material-ui/core";
import Fact from "src/common/landingpage/Fact";
import styled from "styled-components";
import Contact from "../common/logistics/contact";
import LogisticsStage from "../common/components/Stages/LogisticsStage";
import LogisticsService from "src/common/landingpage/LogisticsService";
import { useIntl } from "gatsby-plugin-intl"

export type Color = "moss" | "sage" | "mud";

function generateColorArray(length: number) {
  const factColors: Color[] = ["moss", "sage", "mud"];
  let colorArray = factColors;
  for (var i = 1; i < length / 3; i++) {
    colorArray = colorArray.concat(factColors);
  }
  return colorArray;
}


const LogisticsPage = ({
  data: { contentfulLogisticsPage, contentfulFooter, contentfulHeader },
}: {
  data: LogisticsPageQuery;
}) => {
  if (!contentfulLogisticsPage || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulLogisticsPage, contentfulFooter, contentfulHeader"
    );
  }
  const { pageConfig, contact } = contentfulLogisticsPage;

  const factColors = generateColorArray(contentfulLogisticsPage?.facts!.length);

  const intl = useIntl();

  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      stageContent={
        <LogisticsStage
          title={pageConfig!.title!.title!}
          text={pageConfig!.subtitle?.subtitle!}
          image={pageConfig!.headerImage!.file!.url!}
        />
      }
    >
      <SEO
        title={pageConfig!.seoConfig?.title!}
        description={pageConfig!.seoConfig?.description}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />

      {contentfulLogisticsPage?.services?.map((service) => (
        <LogisticsService
          key={service?.id}
          title={service?.title?.title!}
          text={service?.text?.json!}
          image={service?.image?.file?.url!}
          imagePositionHorizontal={service?.imagePositionHorizontal!}
          imagePositionVertical={service?.imagePositionVertical!}
          backgroundColor={service?.backgroundColor!}
        />
      ))}

      <ContentWidthWrapper padding>
      <FactTitle>{intl.formatMessage({ id: 'logisticServiceReasonsTitle' })}</FactTitle>
        <Grid container justify="center">
          {contentfulLogisticsPage?.facts!.map((fact, index) => (
            <FactWrapper item xs={12} sm={6} md={4} lg={4} key={fact?.id}>
              <Fact
                number={fact!.value!}
                color={factColors[index]}
                subtitle={fact!.title!}
                text={fact!.subtitle!}
              />
            </FactWrapper>
          ))}
        </Grid>
      </ContentWidthWrapper>
      <Contact contact={contact}></Contact>
    </Layout>
  );
};

export default LogisticsPage;

export const query = graphql`
  query LogisticsPage($locale: String!) {
    contentfulLogisticsPage(node_locale: { eq: $locale }) {
      facts {
        id
        title
        value
        subtitle
      }
      contact {
        mailAdress
        telephoneNumber
        title
        telephoneLabel
      }
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
          localFile {
            publicURL
          }
        }
        seoConfig {
          title
          description
          image {
            localFile {
              publicURL
            }
            file {
              url
            }
          }
        }
      }
      services {
        imagePositionVertical
        imagePositionHorizontal
        backgroundColor
        text {
          json
        }
        title {
          title
        }
        id
        image {
          description
          file {
            url
          }
        }
      }
    }
    contentfulFooter(node_locale: { eq: $locale }) {
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader(node_locale: { eq: $locale }) {
      links {
        id
        label
        url
        type
      }
    }
  }
`;

const FactWrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(0, 0, 3)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(0, 1.5, 3, 1.5)};
    &:first-of-type {
      padding: ${theme.spacing(0, 1.5, 3, 0)};
    }
    &:last-of-type {
      padding: ${theme.spacing(0, 0, 3, 1.5)};
    }
  }`);

  const FactTitle = styled(Grid)(
    ({ theme }) => `
    margin-bottom: 66px;
    font-family: ${theme.typography.fontFamily};
    font-size: 35px;
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.carcoal};
    ${theme.breakpoints.down("sm")} {
      margin-bottom: 33px;
    }`
);
