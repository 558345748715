import React from "react";
import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";
import ContentfulImage from "../components/ContentfulImage";
import ContentfulRichText from "../components/ContentfulRichText";
import { Scalars } from "types/graphql-types";

interface LogisticsServiceProps {
  title: string;
  text: Scalars["JSON"] | undefined | null;
  image: string;
  imagePositionHorizontal: string;
  imagePositionVertical: string;
  backgroundColor: string;
}

const LogisticsService = ({
  title,
  text,
  image,
  imagePositionHorizontal,
  imagePositionVertical,
  backgroundColor,
}: LogisticsServiceProps) => {
  return (
    <Root imagePositionVertical={imagePositionVertical}>
      <Stripe
        backgroundColor={backgroundColor}
        imagePositionVertical={imagePositionVertical}
      />
      <Content
        container
        alignItems={imagePositionVertical === "top" ? "flex-start" : "center"}
        direction={imagePositionHorizontal === "right" ? "row-reverse" : "row"}
      >
        <ImageWrapper item xs={12} md={5}>
          <ContentfulImage url={image} />
        </ImageWrapper>
        <TextWrapper
          imagePositionVertical={imagePositionVertical}
          item
          xs={12}
          md={7}
        >
          <Headline variant="h2">{title}</Headline>
          <ContentfulRichText json={text} />
        </TextWrapper>
      </Content>
    </Root>
  );
};

export default LogisticsService;
interface RootProps {
  imagePositionVertical: string;
}
const Root = styled.div<RootProps>(
  (props) => `
    background-color: ${props.theme.palette.dust.main};
    margin-top: ${
      props.imagePositionVertical === "center" ? 0 : props.theme.spacing(5)
    }px;
      padding: ${props.theme.spacing(4, 3)};
      width: 100%;
      margin-bottom: ${props.theme.spacing(3)}px;
      position: relative;
    ${props.theme.breakpoints.up("md")} {
      background-color: transparent;
      margin-bottom: ${
        props.imagePositionVertical === "center" ? 0 : props.theme.spacing(8)
      }px;
      padding: ${
        props.imagePositionVertical === "center"
          ? "60px 0"
          : props.theme.spacing(0, 7)
      };
    }
`
);

interface StripeProps {
  backgroundColor: string;
  imagePositionVertical: string;
}

const Stripe = styled.div<StripeProps>(
  (props) => `
    ${props.theme.breakpoints.up("sm")} {
        background-color: ${
          props.backgroundColor === "brown"
            ? props.theme.palette.dust.main
            : props.theme.palette.white.main
        };
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }


    ${props.theme.breakpoints.up("md")} {
    ${
      props.imagePositionVertical === "center"
        ? `height: calc(100% + 120px);
        top: 0;
    `
        : props.imagePositionVertical === "top"
        ? ` height: 90%;
            top: unset;
            bottom:0;
            padding-bottom:80px;
            padding-top:89px;`
        : `   height: 84%;
              top: 8%;`
    }

    }

    @media (max-width: 1133px) {
        height: 100%;
        top: 0;
    }
`
);

const Content = styled(Grid)(
  ({ theme }) => `
 max-width: 1280px;
 margin:0 auto;
`
);

const ImageWrapper = styled(Grid)(
  ({ theme }) => `
  order:1;
   ${theme.breakpoints.up("md")} {
        order:unset;
    }

`
);
interface TextWrapperProps {
  imagePositionVertical: string;
}

const TextWrapper = styled(Grid)<TextWrapperProps>(
  ({ theme, imagePositionVertical }) => `
    order:0

  h2{
     margin-bottom:17px;
     font-family: Montserrat;
     font-size: 35px;
     font-weight: bold;
     color: #212529;
     hyphens: auto;
     word-wrap: break-word;
  }
  p{
    font-size: 16px;
    line-height: 1.5;
    color: #212529;

}
li{margin-bottom:30px;}
    h3{
      font-size: 24px;
      font-weight: bold;
      color: #212529;
      line-height: 1;
      margin-bottom:15px;

    }
    margin-top: ${theme.spacing(3)}px;
    z-index: 1;
    padding: 0;

    ${theme.breakpoints.up("md")} {
        margin-top: ${imagePositionVertical === "top" ? "10%" : 0};
        margin-bottom: ${imagePositionVertical === "top" ? "80px" : 0};
        padding: 0 5.5%;
        order:unset;
    }
`
);

const Headline = styled(Typography)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(2)}px;
`
);
